<template>
  <div id="repository-card">
    <div class="row items-center">
      <BaseIcon name="eva-archive" color="amber" size="36px" />
      <q-space />
      <BaseIcon name="eva-more-vertical" inherit-color class="icon" />
    </div>

    <div class="col q-mt-md">
      <div class="title">{{ title }}</div>
      <div class="stats">
        <div class="stat">23 Batches</div>
        <BaseIcon name="mdi-circle-small" inherit-color class="icon" />
        <div class="stat">496 Files</div>
        <BaseIcon name="mdi-circle-small" inherit-color class="icon" />
        <div class="stat">2.74 GB</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RepositoryCard",

  props: {
    title: {
      type: String,
      required: true,
    },

    repositorysCount: {
      type: Number,
      default: 0,
    },

    filesCount: {
      type: Number,
      default: 0,
    },

    size: {
      type: String,
      default: "0 KB",
    },
  },
};
</script>

<style lang="scss" scoped>
#repository-card {
  background-color: var(--component-bg-color);
  border-radius: 4px;
  padding: 16px;
  height: 100%;
  cursor: pointer;

  .title {
    font-weight: 600;
    margin-bottom: 4px;
  }

  .icon {
    color: var(--icon-color-inverted);
  }

  .stats {
    display: flex;
    align-items: center;

    .stat {
      @extend .text-sm;
      color: var(--icon-color);
    }
  }
}
</style>
