<template>
  <div id="file-card">
    <div class="thumbnail">
      <img src="@/assets/capture/thumbnail.png" alt="thumnail" />
    </div>

    <div class="row items-center q-mt-md">
      <div class="col">
        <div class="title">{{ title }}</div>
        <div class="stats">
          <div class="stat">2022-Apr-27 12:51 AM</div>
          <BaseIcon name="mdi-circle-small" inherit-color class="icon" />
          <div class="stat">3.6 MB</div>
        </div>
      </div>

      <BaseIcon
        :name="
          selected === title
            ? 'eva-checkmark-circle-2'
            : 'eva-radio-button-off-outline'
        "
        :is-inactive="selected !== title"
        color="secondary"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "FileCard",

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selected: "",
    };
  },

  methods: {
    handleClick() {
      this.selected = this.selected === this.title ? "" : this.title;
    },
  },
};
</script>

<style lang="scss" scoped>
#file-card {
  background-color: var(--component-bg-color);
  border-radius: 4px;
  padding: 16px;
  height: 100%;

  .thumbnail {
    background-color: var(--body-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-radius: 4px;

    img {
      height: 200px;
      width: 100%;
    }
  }

  .title {
    font-weight: 600;
    margin-bottom: 4px;
  }

  .icon {
    color: var(--icon-color-inverted);
  }

  .stats {
    display: flex;
    align-items: center;

    .stat {
      @extend .text-sm;
      color: var(--icon-color);
    }
  }
}
</style>
