<template>
  <div id="scan-import">
    <!-- header -->

    <div class="row items-center q-mb-lg">
      <div>
        <!-- title -->

        <div class="title-1 q-mb-xs">Scan & Import</div>

        <!-- ... -->

        <!-- breadcrumbs -->

        <Breadcrumbs :breadcrumbs="breadcrumbs" />

        <!-- ... -->
      </div>

      <q-space />

      <!-- stats -->

      <div class="stats q-mr-md">
        <div class="stat">
          <BaseIcon name="mdi-file-document" color="deep-orange" size="24px" />

          <div class="q-ml-md">
            <div class="count">2451</div>
            <div class="label">pages scanned</div>
          </div>
        </div>

        <BaseSeparator is-vertical class="q-mx-md" />

        <div class="stat">
          <BaseIcon name="mdi-folder" color="amber" size="24px" />

          <div class="q-ml-md">
            <div class="count">1245</div>
            <div class="label">batches scanned</div>
          </div>
        </div>

        <BaseSeparator is-vertical class="q-mx-md" />

        <div class="stat">
          <BaseIcon
            name="mdi-file-document-multiple"
            color="green"
            size="20px"
          />

          <div class="q-ml-md">
            <div class="count">415</div>
            <div class="label">files archived</div>
          </div>
        </div>
      </div>

      <!-- ... -->

      <!-- quick help -->

      <BaseActionButton
        icon="eva-question-mark"
        color="secondary"
        class="q-mx-md"
        @click="$emit('quickHelp')"
      />

      <!-- ... -->

      <BaseButton icon="eva-printer-outline" label="quick scan" />
    </div>

    <!-- ... -->

    <div class="row items-center">
      <!-- tabs -->

      <Tabs v-model="tab" :tabs="tabs" />

      <!-- ... -->

      <q-space />
      <BaseActionButton icon="mdi-text-box-minus-outline" is-flat no-border />
      <BaseActionButton icon="mdi-text-box-plus-outline" is-flat no-border />

      <BaseSeparator is-vertical has-inset class="q-mx-md" />

      <BaseActionButton icon="mdi-robot-outline" is-flat no-border />
      <BaseActionButton icon="mdi-label-multiple-outline" is-flat no-border />
      <BaseActionButton icon="eva-trash-2-outline" is-flat no-border />

      <BaseSeparator is-vertical has-inset class="q-mx-md" />

      <BaseActionButton icon="mdi-sort" is-flat no-border />
      <BaseActionButton icon="eva-copy-outline" is-flat no-border />
      <BaseActionButton icon="eva-funnel-outline" is-flat no-border />
      <BaseActionButton icon="eva-refresh" is-flat no-border />
    </div>

    <!-- workspaces -->

    <template v-if="activePath === 'ROOT'">
      <SectionTitle class="q-my-lg">Workspaces</SectionTitle>

      <div class="row q-col-gutter-md">
        <div v-for="workspace in workspaces" :key="workspace" class="col-3">
          <WorkspaceCard :title="workspace" />
        </div>
      </div>
    </template>

    <!-- ... -->

    <!-- repositories -->

    <template v-if="activePath === 'WORKSPACE'">
      <SectionTitle class="q-my-lg">Folders</SectionTitle>

      <div class="row q-col-gutter-md">
        <div v-for="repository in repositories" :key="repository" class="col-3">
          <RepositoryCard :title="repository" />
        </div>
      </div>
    </template>

    <!-- ... -->

    <!-- batches -->

    <template v-if="activePath === 'REPOSITORY'">
      <SectionTitle class="q-my-lg">Batches</SectionTitle>

      <div class="row q-col-gutter-md">
        <div v-for="batch in batches" :key="batch" class="col-3">
          <BatchCard :title="batch" />
        </div>
      </div>
    </template>

    <!-- ... -->

    <SectionTitle class="q-my-lg">Files</SectionTitle>

    <div class="row q-col-gutter-md">
      <div v-for="file in files" :key="file" class="col-3">
        <FileCard :title="file" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/common/breadcrumbs/Breadcrumbs.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import SectionTitle from "@/views/capture/components/SectionTitle.vue";
import WorkspaceCard from "./components/WorkspaceCard.vue";
import RepositoryCard from "./components/RepositoryCard.vue";
import BatchCard from "./components/BatchCard.vue";
import FileCard from "./components/FileCard.vue";

export default {
  name: "ScanImport",

  components: {
    Breadcrumbs,
    Tabs,
    SectionTitle,
    WorkspaceCard,
    RepositoryCard,
    BatchCard,
    FileCard,
  },

  data() {
    return {
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "home",
          route: "capture-overview",
        },
        {
          id: this.$nano.id(),
          label: "scan & import",
          route: "capture-scan-import",
        },
        {
          id: this.$nano.id(),
          label: "finance",
          route: "capture-scan-import",
        },
        {
          id: this.$nano.id(),
          label: "insurance companies",
          route: "capture-scan-import",
        },
        {
          id: this.$nano.id(),
          label: "batch #24",
          route: "",
        },
      ],
      tabs: [
        {
          id: this.$nano.id(),
          label: "upload",
          value: "UPLOAD",
        },
        {
          id: this.$nano.id(),
          label: "auto process",
          value: "AUTO_PROCESS",
        },
        {
          id: this.$nano.id(),
          label: "index",
          value: "INDEX",
        },
      ],
      tab: "UPLOAD",
      workspaces: [
        "Finance",
        "Research And Development",
        "Marketing",
        "Production",
        "Human Resource",
        "Customer Support",
      ],
      repositories: [
        "Credit Unions",
        "Banks",
        "Credit Card Companies",
        "Insurance Companies",
        "Stock Brokerages",
        "Accountancy Companies",
      ],
      batches: [
        "Batch #02",
        "Batch #07",
        "Batch #10",
        "Batch #21",
        "Batch #46",
        "Batch #124",
      ],
      files: [
        "Vue road to enterprise.pdf",
        "Kotlin Programming.epub",
        "EZOFIS V5 Menu List.xlsx",
        "Viewer Design.pptx",
        "Balance Sheet.pdf",
        "Income Statement.xlsx",
      ],
      activePath: "ROOT", // WORKSPACE | REPOSITORY | BATCH
    };
  },
};
</script>

<style lang="scss" scoped>
#scan-import {
  .stats {
    display: flex;
    align-items: center;

    .stat {
      display: flex;
      align-items: center;

      .count {
        font-weight: 600;
      }

      .label {
        @extend .text-xs;
        color: var(--icon-color-inverted);
        text-transform: capitalize;
      }
    }
  }

  .link {
    @extend .text-sm;
    text-decoration: underline;
    cursor: pointer;
    color: var(--secondary);
  }
}
</style>
