<template>
  <div id="section-title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
};
</script>

<style lang="scss" scoped>
#section-title {
  color: var(--icon-color);
  font-weight: 500;
  font-size: 13px;
}
</style>
